<template>
    <main class="swcluster-main" id="swcluster-datahub-home">
        <!-- page-banner -->
        <div class="page-banner">
            <div class="banner-image">
                <img src="@/assets/images/datasandbox/datahub_banner.png" alt="" />
            </div>
        </div>
        <!-- page-header -->
        <div class="page-header">
            <CommonBreadcrumb :breadcrumb-classes="['breadcrumb-component']" />
        </div>
        <!-- page-body -->
        <div class="page-body">
            <!-- page-contents -->
            <div class="page-contents">
                <!-- content-section:데이터 허브 플랫폼 -->
                <article class="content-major-section section-component">
                    <div class="major-content">
                        <h3 class="title">데이터 허브 플랫폼</h3>
                        <p class="description">
                            <strong>선순환 데이터 활용 생태계를 만듭니다.</strong>
                            <br /><br />사용자 참여형 플랫폼으로 대구광역시와 제주특별자치도 그리고 기업과 개인이 보유한 데이터를 자유롭게 공유할 수 있는 데이터
                            창구로 공공데이터와 민간데이터의 융합 구축을 만들어 선순환되는 데이터 활용 생태계를 조성하고자 합니다.
                        </p>
                        <router-link :to="{name: 'Conversion'}" class="link">사례보기 <i class="icon-link"></i></router-link>
                    </div>
                    <div class="major-image">
                        <img src="@/assets/images/datahub/datahub_major_1.png" alt="" />
                    </div>
                </article>
                <!-- content-section:데이터 서비스 -->
                <article class="content-section sw-divider">
                    <div class="section-component">
                        <header class="section-header">
                            <h3 class="title">데이터 서비스</h3>
                            <p class="description">모두가 참여하여 쉽게 데이터를 활용하고 <br />시사점 도출을 위한 서비스입니다.</p>
                        </header>
                        <div class="card-list-container card-image-container">
                            <ul class="card-list" data-column="3" data-ratio="9x5">
                                <!-- card-item -->
                                <li class="card-item">
                                    <div class="card">
                                        <div class="card-image">
                                            <img src="@/assets/images/datasandbox/datahub_1_1.png" alt="" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="card-title">데이터 제공</h4>
                                            <p class="card-text">Open API, CSV/XSLX, Link</p>
                                        </div>
                                    </div>
                                </li>
                                <!-- card-item -->
                                <li class="card-item">
                                    <div class="card">
                                        <div class="card-image">
                                            <img src="@/assets/images/datasandbox/datahub_1_2.png" alt="" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="card-title">인포그래픽 제공</h4>
                                            <p class="card-text">차트 인포그래픽, 영상 인포그래픽</p>
                                        </div>
                                    </div>
                                </li>
                                <!-- card-item -->
                                <li class="card-item">
                                    <div class="card">
                                        <div class="card-image">
                                            <img src="@/assets/images/datasandbox/datahub_1_3.png" alt="" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="card-title">리포트 제공</h4>
                                            <p class="card-text">AI 리포트, 정기 리포트</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>
                <!-- content-section:데이터 분석환경 이용 서비스 -->
                <article class="content-section sw-divider">
                    <div class="section-component">
                        <header class="section-header">
                            <h3 class="title">데이터 분석환경 이용 서비스</h3>
                            <p class="description">데이터 분석을 하고자 하는 이용자들에게 분석환경 <br />예약/확인 및 결과를 반출할 수 있는 서비스 입니다.</p>
                        </header>
                        <div class="card-list-container card-image-container">
                            <ul class="card-list" data-column="3" data-ratio="9x5">
                                <!-- card-item -->
                                <li class="card-item">
                                    <div class="card">
                                        <div class="card-image">
                                            <img src="@/assets/images/datasandbox/datahub_2_1.png" alt="" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="card-title">데이터 제공</h4>
                                            <p class="card-text">반출가능, 반출불가 데이터</p>
                                        </div>
                                    </div>
                                </li>
                                <!-- card-item -->
                                <li class="card-item">
                                    <div class="card">
                                        <div class="card-image">
                                            <img src="@/assets/images/datasandbox/datahub_2_2.png" alt="" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="card-title">연구분야</h4>
                                            <p class="card-text">스마트관광, 모빌리티, 거버넌스</p>
                                        </div>
                                    </div>
                                </li>
                                <!-- card-item -->
                                <li class="card-item">
                                    <div class="card">
                                        <div class="card-image">
                                            <img src="@/assets/images/datasandbox/datahub_2_3.png" alt="" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="card-title">분석툴</h4>
                                            <p class="card-text">하드웨어, 소프트웨어 분석</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>

                <!-- content-section:AI Data Mart -->
                <article class="content-section sw-divider">
                    <div class="section-component">
                        <header class="section-header">
                            <h3 class="title">AI Data Mart</h3>
                            <p class="description half-width-desc">
                                SW제품개발에 필요한 비즈니스 인사이트를 발굴할 수 있는 기초 분석환경을 클라우드 형태로 유연하게 제공 뿐만 아니라 알고리즘과
                                인공지능 모델 개발을 위한 데이터 조달을 체계화 시키기 위한 인공지능 데이터 적층 구조를 제공합니다.
                            </p>
                        </header>
                        <div class="image-container">
                            <img src="@/assets/images/datahub/datahub_intro_1.svg" />
                        </div>
                    </div>
                    <div class="section-component">
                        <header class="section-header is-divider">
                            <p class="description half-width-desc">
                                알고리즘과 인공지능 모델 개발에 바로 활용할 수 있는 데이터 제공을 위한 인코딩, Null 제거 및 ETL 처리가 된 AI Data Mart를
                                제공합니다.
                            </p>
                        </header>
                        <div class="image-container">
                            <img src="@/assets/images/datahub/datahub_intro_2.svg" />
                        </div>
                    </div>
                </article>
                <article class="content-section sw-divider">
                    <div class="section-component">
                        <header class="section-header is-top-margin">
                            <h3 class="title">클라우드 인프라 구성</h3>
                        </header>
                        <div class="image-container">
                            <img src="@/assets/images/datahub/datahub_intro_3.svg" />
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <!-- //page-body -->
    </main>
</template>

<script>
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
export default {
    name: 'HubIntro',
    components: {CommonBreadcrumb},
    setup() {
        return {};
    },
};
</script>
